import { hooksFetch, hooksFetchDelete, hooksFetchPatch, hooksFetchPost } from '../core/api'

const qsSeparator = '|'

const stringSchema = {
  toApiQs: (filters, key, value) => {
    filters[key] = value
  },
  fromApiQsToAppQs: (query, key, value) => {
    query[key] = value
  },
  toAppQs: (query, key, value) => {
    query[key] = value
  },
  fromQsToAppFilter(filter, key, value) {
    filter[key] = value
  },
}

const numberSchema = {
  toApiQs: (filters, key, value) => {
    if (Number.isInteger(value)) {
      filters[key] = value
    }
  },
  fromApiQsToAppQs: (query, key, value) => {
    if (Number.isInteger(value)) {
      query[key] = value
    }
  },
  toAppQs: (query, key, value) => {
    if (Number.isInteger(value)) {
      query[key] = value
    }
  },
  fromQsToAppFilter(filter, key, value) {
    const parseValue = Number(value)

    if (Number.isInteger(parseValue)) {
      filter[key] = parseValue
    }
  },
}

const minSchema = (apiQsKey) => ({
  ...numberSchema,
  apiQsKey: [apiQsKey],
  toApiQs: (filters, key, value) => {
    const apiKey = key.replace('Min', '')

    if (!filters[apiKey]) {
      filters[apiKey] = {}
    }
    filters[apiKey]['$gte'] = value
  },
  fromApiQsToAppQs: (query, key, value) => {
    if (Number.isInteger(value?.$gte)) {
      query[`${key}Min`] = value?.$gte
    }
  },
})

const maxSchema = (apiQsKey) => ({
  ...numberSchema,
  apiQsKey: [apiQsKey],
  toApiQs: (filters, key, value) => {
    const apiKey = key.replace('Max', '')

    if (!filters[apiKey]) {
      filters[apiKey] = {}
    }
    filters[apiKey]['$lte'] = value
  },
  fromApiQsToAppQs: (query, key, value) => {
    if (Number.isInteger(value?.$lte)) {
      query[`${key}Max`] = value?.$lte
    }
  },
})

const minMaxSchemaFactory = (apiQsKey) => ({
  [`${apiQsKey}Min`]: minSchema(apiQsKey),
  [`${apiQsKey}Max`]: maxSchema(apiQsKey),
})

const arraySchema = {
  toApiQs: (filters, key, value) => {
    if (Array.isArray(value) && value.length !== 0) {
      filters[key] = value
    }
  },
  fromApiQsToAppQs: (query, key, value) => {
    if (Array.isArray(value) && value.length !== 0) {
      query[key] = encodeURIComponent(value.join(qsSeparator))
    }
  },
  toAppQs: (query, key, value) => {
    if (Array.isArray(value) && value.length !== 0) {
      query[key] = encodeURIComponent(value.join(qsSeparator))
    }
  },
  fromQsToAppFilter(filter, key, value) {
    const parsedValue = decodeURIComponent(value).split(qsSeparator)
    if (Array.isArray(parsedValue)) {
      filter[key] = parsedValue
    }
  },
}

const booleanSchema = {
  toApiQs: (filters, key, value) => {
    if (value === true || value === false) {
      filters[key] = value
    }
  },
  fromApiQsToAppQs: (query, key, value) => {
    if (value === true || value === false) {
      query[key] = value
    }
  },
  toAppQs: (query, key, value) => {
    if (value === true || value === false) {
      query[key] = value
    }
  },
  fromQsToAppFilter(filter, key, value) {
    if (value === 'true' || value === 'false' || value === true || value === false) {
      filter[key] = value === 'true' || value === true
    }
  },
}

export const locationToQuery = (location) => {
  const param = [location.cp, location.ville, location.distance].join(qsSeparator)

  return encodeURIComponent(param)
}

const queryToLocation = (location) => {
  const [cp, ville, distance] = decodeURIComponent(location).split(qsSeparator)
  let finalDistance = distance

  if (typeof Number(finalDistance) === 'number' && !isNaN(Number(finalDistance))) {
    finalDistance = Number(finalDistance)
  }

  return {
    cp,
    ville,
    distance: finalDistance,
  }
}

const agencyToQuery = (agency) => {
  const params = [agency.id, agency.name].join(qsSeparator)

  return encodeURIComponent(params)
}

const queryToAgency = (agency) => {
  const [id, name] = decodeURIComponent(agency).split(qsSeparator)

  return {
    id,
    name,
  }
}

const searchSchema = {
  locations: {
    toApiQs: (filters, key, value) => {
      if (value.length > 0) {
        filters['locations'] = value
      }
    },
    fromApiQsToAppQs: (query, key, value) => {
      if (Array.isArray(value) && value.length !== 0) {
        query['locations'] = value.map((location) => locationToQuery(location))
      }
    },
    toAppQs: (query, key, value) => {
      if (Array.isArray(value) && value.length !== 0) {
        query['locations'] = value.map((location) => locationToQuery(location))
      }
    },
    fromQsToAppFilter(filter, key, value) {
      const locations = []
      if (typeof value === 'string') {
        locations.push(value)
      } else if (Array.isArray(value)) {
        locations.push(...value)
      }

      filter['locations'] = locations.map(queryToLocation)
    },
  },
  ...minMaxSchemaFactory('rendement'),
  ...minMaxSchemaFactory('prix'),
  adType: {
    ...stringSchema,
    apiQsKey: ['hzExclusive'],
    toApiQs: (filters, key, value) => {
      if (value === 'exclusive') {
        filters['hzExclusive'] = true
      }
    },
    fromApiQsToAppQs: (query, key, value) => {
      if (value === true) {
        query['adType'] = 'exclusive'
      }
    },
    fromQsToAppFilter(filter, key, value) {
      if (['all', 'exclusive'].includes(value)) {
        filter[key] = value
      }
    },
  },

  // Advanced filters
  ...minMaxSchemaFactory('prixm2'),
  ...minMaxSchemaFactory('loyer'),
  ...minMaxSchemaFactory('surface'),
  preferredRegime: arraySchema,
  recommendedStrategies: arraySchema,
  ...minMaxSchemaFactory('surface'),
  type: arraySchema,
  coOwnership: booleanSchema,
  neuf: booleanSchema,
  floor: arraySchema,
  ...minMaxSchemaFactory('nbpieces'),
  specificities: {
    ...arraySchema,
    apiQsKey: ['locataireInside', 'travaux'],
    toApiQs: (filters, key, value) => {
      if (value.includes('rented')) {
        filters['locataireInside'] = true
      }
      if (value.includes('renovation')) {
        filters['travaux'] = { $gt: 0 }
      }
    },
    fromApiQsToAppQs: (query, key, value) => {
      const setSpecificity = (specificity) => {
        if (query['specificities'] === undefined) {
          query['specificities'] = specificity
        } else {
          query['specificities'] += `${encodeURIComponent(qsSeparator)}${specificity}`
        }
      }

      if (key === 'locataireInside') {
        if (value === true) {
          setSpecificity('rented')
        }
      } else if (key === 'travaux') {
        if (Number.isInteger(value?.$gt) && value?.$gt === 0) {
          setSpecificity('renovation')
        }
      }
    },
  },
  energyClass: arraySchema,
  ges: arraySchema,
  agencies: {
    toApiQs: (filters, key, value) => {
      if (value.length > 0) {
        filters['agencies'] = value.map((agency) => agency.id)
      }
    },
    fromApiQsToAppQs: (query, key, value) => {
      if (Array.isArray(value) && value.length !== 0) {
        query['agencies'] = value.map((agency) => agencyToQuery({ id: agency, name: 'Agency' }))
      }
    },
    toAppQs: (query, key, value) => {
      if (Array.isArray(value) && value.length !== 0) {
        query['agencies'] = value.map((agency) => agencyToQuery(agency))
      }
    },
    fromQsToAppFilter(filter, key, value) {
      const agencies = []
      if (typeof value === 'string') {
        agencies.push(value)
      } else if (Array.isArray(value)) {
        agencies.push(...value)
      }

      filter['agencies'] = agencies.map(queryToAgency)
    },
  },
  citySize: arraySchema,
  ...minMaxSchemaFactory('freshness'),
  tags: arraySchema,
}

const REAL_ESTATE_OFFER_BASE_V2 = '/v2/real-estate-ads'

export const useTubApi = () => ({
  getCategoryPageContent: async (category, city, postalCode, query) => {
    let tubSlug = ''
    if (category) {
      tubSlug += `/${category}`
    }
    if (city) {
      tubSlug += `/${city}`
    }
    if (postalCode) {
      tubSlug += `/${postalCode}`
    }

    return await hooksFetch(`/v1/cms/page/tub${tubSlug}`, { query })
  },
  find: async (params) => {
    return await hooksFetch(REAL_ESTATE_OFFER_BASE_V2, { params })
  },
  getById: async (id) => {
    return await hooksFetch(`${REAL_ESTATE_OFFER_BASE_V2}/${id}`)
  },
  getTubSchema: () => searchSchema,
  getDataSourceFilters: (filters) => {
    const apiQs = {}

    Object.keys(filters).forEach((key) => {
      const value = filters[key]

      if (searchSchema[key] && value !== null && value !== undefined) {
        searchSchema[key].toApiQs(apiQs, key, value)
      }
    })

    return {
      ...apiQs,
      ignoreAdminRole: true,
    }
  },
  fromApiQsToAppQs: (filters) => {
    const appQs = {}

    Object.keys(filters).forEach((key) => {
      const value = filters[key]

      if (value !== null && value !== undefined) {
        if (searchSchema[key]) {
          searchSchema[key].fromApiQsToAppQs(appQs, key, value)
        } else {
          const schemas = Object.values(searchSchema).filter((schema) => schema.apiQsKey?.includes(key))
          if (schemas.length > 0) {
            for (const schema of schemas) {
              schema.fromApiQsToAppQs(appQs, key, value)
            }
          }
        }
      }
    })

    return {
      ...appQs,
    }
  },
  addToFavorites: async (offerId) => {
    return await hooksFetchPost(`${REAL_ESTATE_OFFER_BASE_V2}/${offerId}/favourite`, { body: {} })
  },
  removeToFavorites: async (offerId) => {
    return await hooksFetchDelete(`${REAL_ESTATE_OFFER_BASE_V2}/${offerId}/favourite`)
  },
  getFavoritesIds: async () => {
    const favorites = await hooksFetch(REAL_ESTATE_OFFER_BASE_V2, {
      params: {
        filter: JSON.stringify({
          favorite: true,
          ignoreAdminRole: true,
        }),
        skip: 0,
        limit: 100,
      },
    })

    return favorites.data.map((favorite) => favorite.id)
  },
  flagAsSeen: async (realEstateOfferId) => {
    return await hooksFetchPatch(`${REAL_ESTATE_OFFER_BASE_V2}/${realEstateOfferId}/seen`, { body: {} })
  },
  getSeens: async () => {
    return await hooksFetch(`${REAL_ESTATE_OFFER_BASE_V2}/seens`)
  },
})
